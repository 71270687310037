import React, { useState, useEffect } from 'react';

interface LoadingBarProps {
  isComplete: boolean;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ isComplete }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isComplete) {
      setProgress(100);
      return;
    }

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 50) {
          // First 50% in 6 seconds
          return Math.min(50, prevProgress + (50 / 60) + Math.random() * 0.5);
        } else if (prevProgress < 85) {
          // Next 35% in 6 seconds (12 seconds total)
          return Math.min(85, prevProgress + (35 / 60) + Math.random() * 0.3);
        } else if (prevProgress < 99) {
          // Last 14% in 13 seconds (25 seconds total)
          return Math.min(99, prevProgress + (14 / 130) + Math.random() * 0.1);
        }
        return prevProgress;
      });
    }, 100);

    return () => clearInterval(timer);
  }, [isComplete]);

  return (
    <div className="w-full h-4 bg-gradient-to-r from-[#48A7FF] from-0% via-[#CD63FF] via-30% to-[#FFB74B] to-55% rounded-full overflow-hidden relative">
      <div 
        className="absolute top-0 right-0 bottom-0 bg-gray-200 transition-all duration-100 ease-linear"
        style={{ left: `${progress}%` }}
      />
    </div>
  );
};

export default LoadingBar;