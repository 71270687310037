import React from 'react';

interface ColorOption {
  name: string;
  value: string;
  borderClass: string;
  hoverClass: string;
  selectedClass: string;
}

interface SubtitleColorSelectorProps {
  currentColor: string;
  onColorChange: (color: string) => void;
}

const colorOptions: ColorOption[] = [
  { 
    name: 'White', 
    value: 'white', 
    borderClass: 'border-white', 
    hoverClass: 'hover:bg-white hover:bg-opacity-20',
    selectedClass: 'bg-white'
  },
  { 
    name: 'Yellow', 
    value: 'yellow', 
    borderClass: 'border-yellow-300', 
    hoverClass: 'hover:bg-yellow-300 hover:bg-opacity-20',
    selectedClass: 'bg-yellow-300'
  },
  { 
    name: 'Green', 
    value: 'green', 
    borderClass: 'border-green-500', 
    hoverClass: 'hover:bg-green-500 hover:bg-opacity-20',
    selectedClass: 'bg-green-500'
  },
  { 
    name: 'Blue', 
    value: 'blue', 
    borderClass: 'border-blue-500', 
    hoverClass: 'hover:bg-blue-500 hover:bg-opacity-20',
    selectedClass: 'bg-blue-500'
  },
  { 
    name: 'Red', 
    value: 'red', 
    borderClass: 'border-red-500', 
    hoverClass: 'hover:bg-red-500 hover:bg-opacity-20',
    selectedClass: 'bg-red-500'
  },
];

const SubtitleColorSelector: React.FC<SubtitleColorSelectorProps> = ({ currentColor, onColorChange }) => {
  return (
    <div className="flex flex-col space-y-2">
      <label className="text-white text-sm font-medium">Text Color</label>
      <div className="flex space-x-2">
        {colorOptions.map((color) => (
          <button
            key={color.value}
            onClick={() => onColorChange(color.value)}
            className={`
              w-8 h-8 border-2 ${color.borderClass} ${color.hoverClass}
              transition-colors duration-200 ease-in-out
              ${currentColor === color.value ? color.selectedClass : 'bg-transparent'}
              rounded-md focus:outline-none
            `}
            title={color.name}
          />
        ))}
      </div>
    </div>
  );
};

export default SubtitleColorSelector;