// ImageWithBoxes.tsx

import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Image as KonvaImage, Rect, Transformer, Group } from 'react-konva';
import useImage from 'use-image';

const MAX_STAGE_WIDTH = 700;  // Reduced from 800
const MAX_STAGE_HEIGHT = 500; // Reduced from 600
const FACE_BOX_STYLE = { stroke: 'red', strokeWidth: 4, fill: 'red', opacity: 0.2 };
const GAMEPLAY_BOX_STYLE = { stroke: 'blue', strokeWidth: 4, fill: 'blue', opacity: 0.2 };
const CORNER_RADIUS = 10;

interface BoxProps {
  coordinates: number[];
  scale: number;
  style: any;
  onTransform: (newCoords: number[]) => void;
  aspectRatio: number;
  stageWidth: number;
  stageHeight: number;
}

const Box: React.FC<BoxProps> = ({ coordinates, scale, style, onTransform, aspectRatio, stageWidth, stageHeight }) => {
  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);

  useEffect(() => {
    if (shapeRef.current && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, []);

  const handleTransform = () => {
    const node = shapeRef.current;
    if (!node) return;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    node.scaleX(1);
    node.scaleY(1);
    
    let newWidth = Math.max(5, node.width() * scaleX);
    let newHeight = newWidth / aspectRatio;
    
    if (newHeight > stageHeight) {
      newHeight = stageHeight;
      newWidth = newHeight * aspectRatio;
    }
    
    if (newWidth > stageWidth) {
      newWidth = stageWidth;
      newHeight = newWidth / aspectRatio;
    }
    
    let newX = Math.max(0, Math.min(node.x(), stageWidth - newWidth));
    let newY = Math.max(0, Math.min(node.y(), stageHeight - newHeight));
    
    node.width(newWidth);
    node.height(newHeight);
    node.position({ x: newX, y: newY });
    
    onTransform([newX / scale, newY / scale, newWidth / scale, newHeight / scale]);
  };

  return (
    <Group>
      <Rect
        ref={shapeRef}
        x={coordinates[0] * scale}
        y={coordinates[1] * scale}
        width={coordinates[2] * scale}
        height={coordinates[3] * scale}
        {...style}
        draggable
        onDragEnd={handleTransform}
        onTransformEnd={handleTransform}
      />
      <Transformer
        ref={trRef}
        boundBoxFunc={(oldBox, newBox) => {
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
        keepRatio
        rotateEnabled={false}
        enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
      />
    </Group>
  );
};

interface ImageWithBoxesProps {
  imageUrl: string;
  facecamStatus: boolean;
  faceCoordinates: number[] | null;
  gameplayCoordinates: number[] | null;
  onFaceBoxChange: (newBox: number[]) => void;
  onGameplayBoxChange: (newBox: number[]) => void;
  onFacecamToggle: (newState: boolean) => void;
  onImageLoad: () => void;
}

const ImageWithBoxes: React.FC<ImageWithBoxesProps> = ({
  imageUrl,
  facecamStatus,
  faceCoordinates,
  gameplayCoordinates,
  onFaceBoxChange,
  onGameplayBoxChange,
  onFacecamToggle,
  onImageLoad
}) => {
  const [image] = useImage(imageUrl);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const stageRef = useRef<any>(null);
  const [scale, setScale] = useState(1);
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (image) {
      setIsLoading(false);
      const scaleX = MAX_STAGE_WIDTH / image.width;
      const scaleY = MAX_STAGE_HEIGHT / image.height;
      const newScale = Math.min(scaleX, scaleY, 1);
      setScale(newScale);
      setStageSize({
        width: image.width * newScale,
        height: image.height * newScale
      });
      onImageLoad();
    }
  }, [image, onImageLoad]);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onerror = () => {
      setError('Failed to load image');
      setIsLoading(false);
    };
  }, [imageUrl]);

  const effectiveFaceCoordinates = faceCoordinates || [0, 0, 384, 216];
  const effectiveGameplayCoordinates = gameplayCoordinates || [518, 0, 883, 1080];

  if (isLoading) return <p className="text-white">Loading image...</p>;
  if (error) return <p className="text-red-500">{error}</p>;
  if (!image) return <p className="text-white">No image available</p>;

  return (
    <div className="space-y-4">
      <Stage ref={stageRef} width={stageSize.width} height={stageSize.height}>
        <Layer>
          <KonvaImage
            image={image}
            width={stageSize.width}
            height={stageSize.height}
            cornerRadius={CORNER_RADIUS}
          />
          {facecamStatus && (
            <>
              <Box
                coordinates={effectiveFaceCoordinates}
                scale={scale}
                style={FACE_BOX_STYLE}
                onTransform={onFaceBoxChange}
                aspectRatio={effectiveFaceCoordinates[2] / effectiveFaceCoordinates[3]}
                stageWidth={stageSize.width}
                stageHeight={stageSize.height}
              />
              <Box
                coordinates={effectiveGameplayCoordinates}
                scale={scale}
                style={GAMEPLAY_BOX_STYLE}
                onTransform={onGameplayBoxChange}
                aspectRatio={effectiveGameplayCoordinates[2] / effectiveGameplayCoordinates[3]}
                stageWidth={stageSize.width}
                stageHeight={stageSize.height}
              />
            </>
          )}
        </Layer>
      </Stage>
      <div className="flex items-center justify-center space-x-4">
        <label className="switch">
          <input
            type="checkbox"
            checked={facecamStatus}
            onChange={() => onFacecamToggle(!facecamStatus)}
            className="sr-only"
          />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default ImageWithBoxes;
